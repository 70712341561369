import React, { Component } from 'react';
import apicaller from '../util/apiCaller';
import swal from 'sweetalert';
import _ from 'lodash';

class UserPhone extends Component {

    constructor() {
        super();
        this.state = {
            agencyId: '',
            communityId: '',
            phone: '',
            agency: {},
            community: {},
            isSendingOtp: false,
        }
    }

    componentDidMount() {
        this.setState({
            agencyId: this.props.match.params.agencyId,
            communityId: this.props.match.params.communityId
        }, () => {
            this.fetchAgency(this.state.agencyId);
            this.fetchCommunity(this.state.communityId);
        })
    }

    fetchAgency = (agencyId) => {
        apicaller('get', `agencys/${agencyId}`)
            .then(res => {
                if (res.data.status === "Success") {
                    this.setState({
                        agency: res.data.data.agency
                    })
                    localStorage.setItem('agency', res.data.data.agency.name);
                    localStorage.setItem('agencyId', res.data.data.agency._id);
                }
                else {
                    alert('Alert', 'Some internal issue', 'warning');
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    fetchCommunity = (communityId) => {
        apicaller('get', `apartments/${communityId}`)
            .then(res => {
                if (res.data.status === "Success") {
                    this.setState({
                        community: res.data.data.apartment
                    })
                    localStorage.setItem('community', res.data.data.apartment.name);
                    localStorage.setItem('communityId', res.data.data.apartment._id);
                    localStorage.setItem('locationId', res.data.data.apartment.locationId);
                }
                else {
                    alert('Alert', 'Some internal issue', 'warning');
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    handleValidationForPhone = (phone) => {
        let isValid = true;
        let message = '';
        if (!phone) {
            isValid = false;
            message = 'Please enter phone number';
        } else if (phone.toString().length !== 10) {
            isValid = false;
            message = 'Please enter valid phone number';
        }
        return {
            isValid,
            message,
        };
    };

    getUserOtp = () => {
        let phone = this.state.phone;
        let check = this.handleValidationForPhone(phone);
        if (check.isValid) {
            this.setState({
                isSendingOtp: true
            })
            let data = {
                phone: phone
            }
            apicaller('post', 'auth/customer-request-otp', data)
                .then(res => {
                    if (res.data.status === "Success") {
                        if (res.data.data.isOTPSent) {
                            localStorage.setItem('phone', phone);
                            this.props.history.push('/verify');
                        }
                        else {
                            swal('Alert', res.data.data.message, 'warning');
                        }
                    }
                    else {
                        alert('Alert', 'Some internal issue', 'warning');
                    }
                    this.setState({
                        isSendingOtp: false
                    })
                })
                .catch(err => {
                    console.log(err);
                    this.setState({
                        isSendingOtp: false
                    })
                })
        } else {
            swal('Alert', check.message, 'warning');
            this.setState({
                isSendingOtp: false
            })
        }
    }

    render() {
        return (
            <div>
                {_.isEmpty(this.state.agency) && _.isEmpty(this.state.community) ?
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#FFFFFF50' }}>
                        <div class="loader"></div>
                    </div>
                    :
                    <div className="container">
                        <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100vh' }}>
                            <div className="mt-5">
                                <img src="/images/honc_logo.png" />
                            </div>
                            <div style={{
                                border: '5px solid #0569FF',
                            }} className="rounded-circle mt-4">
                                <img src="/images/demo_pic.png" className="rounded-circle" style={{ height: 100, width: 100 }} />
                            </div>
                            <div className="mt-4">
                                <p
                                    style={{
                                        color: "#FFFFFF",
                                        fontSize: 16
                                    }}
                                >{this.state.agency && this.state.agency.name ? this.state.agency.name.toUpperCase() : null} AGENCY</p>
                            </div>
                            <div className="d-flex flex-row">
                                <img src="/images/pin.png" style={{ height: 15, width: 10 }} />
                                <p
                                    style={{
                                        color: "#FFD400",
                                        fontSize: 12,
                                        marginLeft: 5
                                    }}
                                >{this.state.community.name}</p>
                            </div>
                            <div>
                                <p
                                    style={{
                                        color: "#FFFFFF",
                                        fontSize: 12,
                                        paddingLeft: '20%',
                                        paddingRight: '20%'
                                    }}
                                    className="text-center"
                                >
                                    {`you are subscribing to ${this.state.agency && this.state.agency.name ? this.state.agency.name.toUpperCase() : null} AGENCY car and bike cleaning services`}
                                </p>
                            </div>
                            <form onSubmit={(e) => { e.preventDefault(); this.getUserOtp(); }}>
                                <div className="mt-2">
                                    <input type='text' maxlength="10" id="number" className="form-control" onChange={(e) => { this.setState({ phone: e.target.value }) }} placeholder="Enter your mobile number" />
                                </div>
                                <div className="mt-4">
                                    <button className="btn" onClick={this.getUserOtp} disabled={this.state.isSendingOtp} style={{
                                        backgroundColor: '#0569FF',
                                        color: '#FFFFFF',
                                        borderRadius: 50,
                                        paddingLeft: 30,
                                        paddingRight: 30,
                                        display: 'block',
                                        margin: '0 auto'
                                    }}>{this.state.isSendingOtp ? 'submitting' : 'submit'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default UserPhone;
