import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Error from './screens/Error';
import finalScreen from './screens/finalScreen';
import UserPhone from './screens/UserPhone';
import verifyOtp from './screens/verifyOtp';


class App extends Component {
  constructor() {
    super();
    this.state = {
    }
  }

  render() {
    return (
      <BrowserRouter forceRefresh={true}>
        <div>
          {/* <Navigation /> */}
          <Switch>
            <Route path="/:agencyId/:communityId" component={UserPhone} exact />
            <Route path="/verify" component={verifyOtp} exact />
            <Route path="/invite-completed" component={finalScreen} exact />
            <Route component={Error} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;