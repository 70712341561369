import React, { Component } from 'react';

class Error extends Component {

    constructor() {
        super();
        this.state = {
        }
    }

    componentDidMount() {
    }


    render() {
        return (
            <div>
            </div>
        )
    }
}

export default Error;
