import React, { Component } from 'react';
import OtpInput from 'react-otp-input';
import swal from 'sweetalert';
import apicaller from '../util/apiCaller';
import _ from 'lodash';


class verifyOtp extends Component {

    constructor() {
        super();
        this.state = {
            otp: '',
            agency: {},
            community: {},
            isResendingOtp: false,
            isVerifingOtp: false
        }
    }

    handleChange = (otp) => {
        this.setState({ otp })
    }

    handleValidationForOTP = (otp) => {
        let isValid = true;
        let message = '';
        if (!otp) {
            isValid = false;
            message = 'Please enter the valid OTP';
        } else if (otp.toString().length !== 4) {
            isValid = false;
            message = 'Please enter the valid OTP';
        }
        return {
            isValid,
            message,
        };
    };

    verifyOtp = () => {
        let check = this.handleValidationForOTP(this.state.otp);
        if (check.isValid) {
            this.setState({
                isVerifingOtp: true
            })
            let data = {
                phone: localStorage.getItem('phone'),
                otp: this.state.otp,
                invite: {
                    agencyId: localStorage.getItem('agencyId'),
                    apartmentId: localStorage.getItem('communityId'),
                    locationId: localStorage.getItem('locationId')
                }
            }
            apicaller('post', `auth/invite/authenticate-otp`, data)
                .then((res) => {
                    if (res.data.status === 'Success') {
                        if (res.data.data.isUserFound) {
                            this.props.history.push('/invite-completed');
                        } else {
                            if (res && res.data.data && res.data.data.msg) {
                                localStorage.setItem('errorMessage', res.data.data.msg)
                                this.props.history.push('/invite-completed');
                            }
                            if (res && res.data.data && res.data.data.message) {
                                swal('Alert', res.data.message, 'warning');
                            }
                        }
                    } else {
                        if (res && res.data.data && res.data.data.message) {
                            swal('Alert', res.data.message, 'warning');
                        }
                    }
                    this.setState({ otp: '' });
                    this.setState({
                        isVerifingOtp: false
                    })
                })
                .catch(err => {
                    console.log(err.response.data);
                    if (err.response && err.response.data && !err.response.data.isUserFound) {
                        swal('Alert', 'Invalid OTP', 'warning');
                    }
                    this.setState({
                        isVerifingOtp: false
                    })
                })
        } else {
            swal('Alert', check.message, 'warning');
            this.setState({
                isVerifingOtp: false
            })
        }
    }

    handleValidationForPhone = (phone) => {
        let isValid = true;
        let message = '';
        if (!phone) {
            isValid = false;
            message = 'Please enter phone number';
        } else if (phone.toString().length !== 10) {
            isValid = false;
            message = 'Please enter valid phone number';
        }
        return {
            isValid,
            message,
        };
    };

    getUserOtp = () => {
        let phone = localStorage.getItem('phone');
        let check = this.handleValidationForPhone(phone);
        if (check.isValid) {
            this.setState({
                isResendingOtp: true
            })
            let data = {
                phone: phone
            }
            apicaller('post', 'auth/customer-request-otp', data)
                .then(res => {
                    if (res.data.status === "Success") {
                        if (res.data.data.isOTPSent) {
                        }
                        else {
                            swal('Alert', res.data.data.message, 'warning');
                        }
                    }
                    else {
                        alert('Alert', 'Some internal issue', 'warning');
                    }
                    this.setState({
                        isResendingOtp: false
                    })
                })
                .catch(err => {
                    console.log(err);
                    this.setState({
                        isResendingOtp: false
                    })
                })
        } else {
            this.setState({
                isResendingOtp: false
            })
            swal('Alert', check.message, 'warning');
        }
    }

    handleResendOtp = () => {
        this.getUserOtp();
    }

    render() {
        let agency = localStorage.getItem('agency');
        let community = localStorage.getItem('community');
        return (
            <div>
                {agency && agency == "" && community && community == "" ?
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#FFFFFF50' }}>
                        <div class="loader"></div>
                    </div>
                    :
                    <div className="container">
                        <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100vh' }}>
                            <div className="mt-5">
                                <img src="/images/honc_logo.png" />
                            </div>
                            <div style={{
                                border: '5px solid #0569FF',
                            }} className="rounded-circle mt-4">
                                <img src="/images/demo_pic.png" className="rounded-circle" style={{ height: 100, width: 100 }} />
                            </div>
                            <div className="mt-4">
                                <p
                                    style={{
                                        color: "#FFFFFF",
                                        fontSize: 16
                                    }}
                                >{agency ? agency.toUpperCase() : null} AGENCY</p>
                            </div>
                            <div className="d-flex flex-row">
                                <img src="/images/pin.png" style={{ height: 15, width: 10 }} />
                                <p
                                    style={{
                                        color: "#FFD400",
                                        fontSize: 12,
                                        marginLeft: 5
                                    }}
                                >{community}</p>
                            </div>
                            <div>
                                <p
                                    style={{
                                        color: "#FFFFFF",
                                        fontSize: 12,
                                        paddingLeft: '20%',
                                        paddingRight: '20%'
                                    }}
                                    className="text-center"
                                >
                                    enter the otp sent to your mobile number
                            </p>
                            </div>
                            <form onSubmit={(e) => { e.preventDefault(); this.verifyOtp(); }} style={{
                                marginBottom: 50,
                            }}>
                                <div className="mt-3">
                                    <OtpInput
                                        value={this.state.otp}
                                        onChange={this.handleChange}
                                        numInputs={4}
                                        isInputNum={true}
                                        separator={<span style={{ paddingLeft: 15, paddingRight: 15 }}></span>}
                                        inputStyle={{
                                            width: 50,
                                            backgroundColor: '#191919',
                                            border: 0,
                                            borderBottom: '1px solid #FFFFFF',
                                            color: '#FFFFFF'
                                        }}
                                        shouldAutoFocus={true}
                                        focusStyle={{
                                            outline: 'none'
                                        }}
                                    />
                                </div>
                                <div className="mt-4 text-center">
                                    <a
                                        onClick={this.handleResendOtp}
                                        disabled={this.state.isResendingOtp}
                                        style={{
                                            color: "#FFFFFF",
                                            fontSize: 16,
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {this.state.isResendingOtp ? 'loading...' : 'Resend OTP'}
                                    </a>
                                </div>
                                <div className="mt-3">
                                    <button className="btn"
                                        disabled={this.state.isVerifingOtp}
                                        onClick={() => this.verifyOtp()}
                                        style={{
                                            backgroundColor: '#0569FF',
                                            color: '#FFFFFF',
                                            borderRadius: 50,
                                            paddingLeft: 30,
                                            paddingRight: 30,
                                            display: 'block',
                                            margin: '0 auto'
                                        }}>{this.state.isVerifingOtp ? 'submitting' : 'submit'}</button>
                                </div>
                            </form>
                            <div style={{ bottom: 0 }} className="d-flex flex-row text-center pl-5 pr-5">
                                <p style={{ color: '#FFFFFF' }}><input type="checkbox" checked disabled /> by continuing, you agree to honc’s <a href="https://honc.io/terms/">terms of use</a> and <a href="https://honc.io/privacy-policy/">privacy policy</a>.</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default verifyOtp;
