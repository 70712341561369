import axios from "axios";
// export const BASE_URL = "http://192.168.29.212:8000/";
// export const BASE_URL = "http://192.168.0.2:8000/";
// export const BASE_URL = "https://devapi.honc.io/";
export const BASE_URL = "https://api.honc.io/";


export default async function apicaller(method, endpoint, data) {
    let url = BASE_URL + endpoint;
    console.log(url);
    let api_response = await axios({
        method,
        url,
        data
    })
        .then((res) => {
            return res;
        })
    return api_response
}