import React, { Component } from 'react';
import apicaller from '../util/apiCaller';
import swal from 'sweetalert';

class finalScreen extends Component {

    constructor() {
        super();
        this.state = {
        }
    }

    componentDidMount() {
        if (localStorage.getItem('errorMessage')) {
            swal('Alert', localStorage.getItem('errorMessage'));
        }
        localStorage.clear();
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100vh' }}>
                        <div>
                            <p
                                style={{
                                    color: '#FFFFFF'
                                }}
                            >Download</p>
                            <img src="/images/honc_logo.png" />
                        </div>
                        <div style={{
                            marginTop: '10%'
                        }}>
                            <a href="https://apps.apple.com/in/app/honc/id1520441184">
                                <img src="/images/ios.png" />
                            </a>
                        </div>
                        <div style={{
                            marginTop: '3%'
                        }}>
                            <a href="https://play.google.com/store/apps/details?id=com.honc_customer&hl=en_IN">
                                <img src="/images/android.png" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default finalScreen;
